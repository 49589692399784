import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { FleetCategory as V3FleetCategory } from '@jucy/rentals-api-client/rentals-api-v3';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, CardContent, CardMedia, Collapse, List, ListItem, Stack, SvgIconProps, Typography, styled, Grid2 as Grid } from '@mui/material';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';
import numWords from 'num-words';
import FleetCategoryAvailability from '../../lib/api/model/FleetCategoryAvailability';
import type { CatalogItem } from '../../lib/api/model/RentalCatalog';
import { FleetType } from '../../services';
import AppStateStore from '../../store/AppStateStore';
import FleetTypesStore from '../../store/FleetTypesStore';
import ProductStore from '../../store/ProductStore';
import RouteRegistry from '../../store/RouteRegistry';
import { BookingCart } from '../../types/BookingCart';
import { JucyButton, JucyButtonProps } from '../Button/Button';

interface ExpandMoreProps extends SvgIconProps {
    expand: boolean;
}

const ExpandMore = styled(({ expand, ...props }: ExpandMoreProps) => <ExpandMoreIcon {...props} />)(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export interface ReservationSummaryCardProps {
    summary: BookingCart;
    editable?: boolean;
    className?: string;
}

const ReservationSummaryCard: React.FC<ReservationSummaryCardProps> = observer(({ summary, editable = true, className }) => {
    const [showExtras, setShowExtras] = useState(false);
    const toggleShowExtras: JucyButtonProps['onClick'] = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setShowExtras(!showExtras);
    };

    const { extras, numberOfExtras } = useMemo(() => {
        const extras = [...(summary.mandatoryItems || []), ...((summary.selectedExtras || []).slice().sort((p) => p.sortOrder) || [])];
        const numberOfExtras = startCase(numWords(extras.length));
        return { extras, numberOfExtras };
    }, [summary.mandatoryItems, summary.selectedExtras]);

    const fleetCategory = useMemo(
        () =>
            FleetCategoryAvailability.formV3Api({
                apiFleetCategory: summary.fleetCategory as V3FleetCategory,
                fleetType: FleetTypesStore.getFleetTypeBySlug(summary.fleetCategory?.fleetTypeCode) as FleetType,
                catalogData: ProductStore.getProductByCode(summary.fleetCategory?.categoryCode, summary.pickUpLocation?.CountryCode) as CatalogItem,
            }),
        [summary.fleetCategory, summary.pickUpLocation?.CountryCode]
    );
    return (
        <Card className={className} variant="outlined">
            <CardContent sx={{ maxWidth: '100%', '&:last-child': { pb: 2 } }}>
                <Grid container spacing={3}>
                    <Grid
                        sx={{ textAlign: 'center' }}
                        size={{
                            sm: 12,
                            md: 4,
                        }}
                    >
                        <CardMedia component="img" image={fleetCategory.product.image} alt={fleetCategory.product.name} />
                        <Typography component="div" fontWeight="700" fontSize="18px" mt={1}>
                            {fleetCategory.Name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" fontSize="14px" component="div">
                            {fleetCategory.product.vehicleDescription}
                        </Typography>
                    </Grid>
                    <Grid
                        size={{
                            sm: 12,
                            md: 8,
                        }}
                    >
                        <Stack spacing={2}>
                            <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: 'column', sm: 'row' }}>
                                <div>
                                    <div className="fw-bold smaller text-muted">Pick up</div>
                                    <div className="d-flex align-items-center">
                                        <h6 className="fw-bold my-2">{format(summary.pickUpDate as Date, 'd MMM yyyy')}</h6>
                                        {summary.reservationReference && editable && (
                                            <Link
                                                className="smaller ms-3 fw-bold"
                                                to={RouteRegistry.getPath('direct-edit-quote', {
                                                    mode: AppStateStore.mode,
                                                    reservationReference: summary.reservationReference,
                                                })}
                                            >
                                                Change
                                            </Link>
                                        )}
                                    </div>
                                    <div>{summary.pickUpLocation?.Name}</div>
                                    {format(summary.pickUpDate as Date, 'h:mm a')}
                                </div>
                                <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
                                    <ArrowForwardIcon sx={{ display: { sm: 'inline', xs: 'none' } }} fontSize="small" color="action" />
                                </Box>
                                <div>
                                    <div className="fw-bold smaller text-muted">Drop off</div>
                                    <div className="d-flex align-items-center">
                                        <h6 className="fw-bold my-2">{format(summary.dropOffDate as Date, 'd MMM yyyy')}</h6>
                                        {summary.reservationReference && editable && (
                                            <Link
                                                className="smaller ms-3 fw-bold"
                                                to={RouteRegistry.getPath('direct-edit-quote', {
                                                    mode: AppStateStore.mode,
                                                    reservationReference: summary.reservationReference,
                                                })}
                                            >
                                                Change
                                            </Link>
                                        )}
                                    </div>
                                    <div>{summary.dropOffLocation?.Name}</div>
                                    {format(summary.dropOffDate as Date, 'h:mm a')}
                                </div>
                            </Stack>
                            {summary.selectedExcess ? (
                                <List
                                    subheader={
                                        <ListItem className="fw-bold smaller text-muted" disablePadding>
                                            Excess reduction
                                            {summary.reservationReference && editable && (
                                                <Link
                                                    className="smaller ms-3 fw-bold"
                                                    to={RouteRegistry.getPath('direct-edit-quote', {
                                                        mode: AppStateStore.mode,
                                                        reservationReference: summary.reservationReference,
                                                        activeTab: 'excess-reduction',
                                                    })}
                                                >
                                                    Change
                                                </Link>
                                            )}
                                        </ListItem>
                                    }
                                >
                                    <ListItem disablePadding>{summary.selectedExcess.name}</ListItem>
                                </List>
                            ) : null}
                            {extras.length ? (
                                <List
                                    component="div"
                                    subheader={
                                        <ListItem component="div" className="fw-bold smaller text-muted" disablePadding>
                                            <JucyButton
                                                color="charcoal"
                                                size="small"
                                                className="fw-bold smaller text-muted"
                                                sx={{ ml: '-6px' }}
                                                variant="text"
                                                endIcon={
                                                    <ExpandMore expand={showExtras}>
                                                        <ExpandMoreIcon />
                                                    </ExpandMore>
                                                }
                                                onClick={toggleShowExtras}
                                            >
                                                {numberOfExtras} extra{extras.length !== 1 && 's'}{' '}
                                            </JucyButton>
                                            {summary.reservationReference && editable && (
                                                <Link
                                                    className="smaller ms-3 fw-bold"
                                                    to={RouteRegistry.getPath('direct-edit-quote', {
                                                        mode: AppStateStore.mode,
                                                        reservationReference: summary.reservationReference,
                                                        activeTab: 'extras',
                                                    })}
                                                >
                                                    Change
                                                </Link>
                                            )}
                                        </ListItem>
                                    }
                                >
                                    <Collapse in={showExtras} timeout="auto" unmountOnExit>
                                        {extras.map((l) => (
                                            <ListItem component="div" disablePadding key={l.productCode || l.productId}>
                                                {l.allowMultiple ? `${l.qty} x` : ''} {l.name}
                                            </ListItem>
                                        ))}
                                    </Collapse>
                                </List>
                            ) : null}
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
});
ReservationSummaryCard.displayName = 'ReservationSummaryCard';
export default ReservationSummaryCard;
