import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { createSearchParams, Navigate, URLSearchParamsInit } from 'react-router-dom';
import FatalError from '../../components/Alert/FatalError';
import { DirectTripSearchAndResult } from '../../components/Forms';
import { validateSearchValues } from '../../components/Forms/TripSearchForm/SearchForm';
import SummaryColumnLayout from '../../containers/SummaryColumnLayout';
import { useRouteNavigate, useSearchParams, useTripApi } from '../../hooks';
import { useDirectSubmitSearchValues } from '../../hooks/useDirectSumbitSearchValues';
import AvailabilityStore from '../../store/AvailabilityStore';
import fleetTypesStore from '../../store/FleetTypesStore';
import rentalTripSearchStore from '../../store/RentalTripSearchStore';
import RentalTripSearchStore from '../../store/RentalTripSearchStore';
import ReservationStore from '../../store/ReservationStore';
import PageLoader from '#/components/LoadingAnimation/PageLoader';
import { PageContainer } from '#/containers/PageContainer';
import { DirectTripSearchForm } from '#/components/Forms';
import { Card, CardContent, GlobalStyles, Typography, useMediaQuery, useTheme } from '@mui/material';
import starBg from '#/styles/images/star/background/children-running-around-outside-motorhome.jpg';
import jucyBg from '#/styles/images/jucy/background/Jucy-Background-2022.jpg';
import { useBrand } from '#/store/BrandStore';

const DirectCreateQuotePage: React.FC = observer(() => {
    const { countryCode, fleetType: fleetTypeSlug, mode } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useRouteNavigate();
    const tripEnabled = useTripApi();
    useEffect(() => {
        if ((fleetTypeSlug && fleetTypeSlug !== RentalTripSearchStore.fleetType?.slug) || searchParams.dealId !== RentalTripSearchStore.selectedDeal?.id) {
            RentalTripSearchStore.init({ dealId: searchParams.dealId, fleetTypeSlug });
            ReservationStore.reset();
            AvailabilityStore.reset();
        } else {
            RentalTripSearchStore.done();
        }
    }, [searchParams.dealId, fleetTypeSlug]);
    const onSubmitMutation = useDirectSubmitSearchValues();

    useEffect(() => {
        if (tripEnabled) {
            navigate('create-trip', {
                params: {
                    countryCode,
                    mode,
                    fleetTypeOrResRef: fleetTypeSlug,
                    action: 'create',
                },
                search: searchParams as Record<string, string>,
                replace: true,
            });
        }
    }, [countryCode, fleetTypeSlug, mode, navigate, searchParams, tripEnabled]);

    useEffect(() => {
        if (searchParams.action === 'search' && onSubmitMutation.isIdle && RentalTripSearchStore.state === 'done') {
            const values = {
                ...rentalTripSearchStore.convertValuesFromQueryString(searchParams as URLSearchParamsInit),
                driverAge: searchParams.driverAge || rentalTripSearchStore.driverAge || '21',
                fleetType: fleetTypesStore.fleetTypes.find((f) => f.slug === fleetTypeSlug),
            };
            if (!validateSearchValues(values)) {
                return;
            }
            onSubmitMutation
                .mutateAsync({
                    values,
                    resultsRoute: {
                        routeId: 'direct-create-quote',
                    },
                })
                .catch(() => undefined);
        }
    }, [fleetTypeSlug, mode, navigate, onSubmitMutation, searchParams, tripEnabled]);

    if (!fleetTypeSlug && !searchParams.dealId) {
        return (
            <Navigate
                to={{
                    pathname: '/direct/create-quote/campervan',
                    search: createSearchParams(searchParams as Record<string, string>).toString(),
                }}
                replace
            />
        );
    }

    if (tripEnabled) {
        return <PageLoader id="direct-trip-redirect" hasContainer={true} />;
    }

    if (RentalTripSearchStore.state === 'pending') {
        return <PageLoader id="direct-create-quote-page" hasContainer={true} />;
    }

    if (RentalTripSearchStore.state === 'error') {
        return <FatalError title={RentalTripSearchStore.message} />;
    }

    return <DirectCreateQuotePageContent />;
});
DirectCreateQuotePage.displayName = 'PublicQuotePageLoader';

const DirectCreateQuotePageContent: React.FC = observer(() => {
    const theme = useTheme();
    const { brand } = useBrand();
    const breakPoint = theme.breakpoints.up('md');
    const matches = useMediaQuery(breakPoint);
    const showAlt = matches && brand === 'star' && (!ReservationStore.summary?.hasTripInfo || !AvailabilityStore.hasTripInfo);
    if (showAlt) {
        return (
            <>
                <GlobalStyles
                    styles={{
                        '#__next': {
                            [breakPoint]: {
                                backgroundAttachment: 'fixed',
                                backgroundColor: theme.palette.grey[50],
                                backgroundImage: 'var(--page-bg)',
                                backgroundPositionX: 'center',
                                backgroundPositionY: `calc(${theme.settings?.appBar.spacer().lg} - ${theme.settings?.appBar.borderTop})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                            },
                        },
                        '[data-brand="jucy"]': {
                            '--page-bg': `url("${jucyBg.src}")`,
                        },
                        '[data-brand="star"]': {
                            '--page-bg': `url("${starBg.src}")`,
                        },
                    }}
                />
                <PageContainer sx={{ display: 'block' }}>
                    <Card sx={{ overflow: 'visible', mt: 3, borderRadius: '24px' }}>
                        <CardContent>
                            <Typography variant="h5" component="div" sx={{ fontWeight: 400 }}>
                                Book your next adventure
                            </Typography>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 300, fontSize: '1rem', mb: 2 }}>
                                Travel in comfort and style in our spacious motorhomes.
                            </Typography>
                            <DirectTripSearchForm layout="horizontal" onSelectRouteDetails={{ routeId: 'direct-create-quote' }} className="booking-widget" />
                        </CardContent>
                    </Card>
                </PageContainer>
            </>
        );
    }
    return (
        <SummaryColumnLayout routeId="direct-edit-quote" summary={ReservationStore.summary}>
            <DirectTripSearchAndResult
                searchFormTitle="Let's find your ideal vehicle"
                classNames={{
                    searchFrom: 'booking-widget large',
                    searchFromContainer: 'card border bg-light rounded mb-4',
                }}
            />
        </SummaryColumnLayout>
    );
});
DirectCreateQuotePageContent.displayName = 'DirectCreateQuotePageContent';

export default DirectCreateQuotePage;
