import React, { useCallback } from 'react';
import { Dialog, DialogProps, IconButton, ModalProps, useMediaQuery, useTheme } from '@mui/material';
import { mergeSx } from '#/lib/mergeSx';

const ModalDialog: React.FC<DialogProps> = ({ open, onClose, children, sx, ...props }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const toggleModel: ModalProps['onClose'] = useCallback(
        (e: object, r: 'backdropClick' | 'escapeKeyDown') => {
            if (onClose) {
                onClose(e, r);
            }
        },
        [onClose]
    );
    return (
        <Dialog
            open={open}
            onClose={toggleModel}
            fullWidth={true}
            fullScreen={fullScreen}
            sx={mergeSx(
                {
                    '&:first-of-type': {
                        paddingTop: 0,
                    },
                },
                sx
            )}
            {...props}
        >
            <IconButton
                color="primary"
                sx={{
                    position: 'absolute',
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                    height: 40,
                    width: 40,
                    zIndex: 1,
                }}
                aria-label="Close"
                onClick={(e) => toggleModel(e, 'backdropClick')}
                size="large"
            >
                <span aria-hidden="true">×</span>
            </IconButton>
            {children}
        </Dialog>
    );
};

export default ModalDialog;
