import get from 'lodash/get';
import { makeAutoObservable, runInAction } from 'mobx';
import { FleetType } from '../services';
import { CACHE_TTL, cache } from '../services/cache';
import jucyRentalsApiClient from '../services/jucyRentalsApi';
import appState from './AppStateStore';

class FleetTypesStore {
    fleetTypes: FleetType[] = [];

    activeFleetTypeSlug: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async initialize() {
        const apiFleetTypes = await cache.get('fleetTypes-v2', () => jucyRentalsApiClient.fetchFleetTypes(), CACHE_TTL.FIVE_MIN);
        if (apiFleetTypes) {
            runInAction(() => {
                this.fleetTypes = apiFleetTypes;
            });
        }
    }

    setActiveFleetTypeSlug(fleetTypeSlug: string) {
        this.activeFleetTypeSlug = fleetTypeSlug;
    }

    getActiveFleetType(): FleetType | null {
        const result = this.activeFleetTypeSlug ? this.getFleetTypeBySlug(this.activeFleetTypeSlug) : null;
        return result || null;
    }

    getFleetTypeBySlug(slug?: string): FleetType | null {
        const result = slug ? this.fleetTypes.find((ft) => ft.slug === slug) : null;
        return result || null;
    }

    getFleetTypeById(id?: string): FleetType | null {
        const result = id ? this.fleetTypes.find((ft) => ft.id?.toLowerCase() === id.toLowerCase()) : null;
        return result || null;
    }

    getDefault(): FleetType {
        if (appState.brand === 'star') {
            return this.fleetTypes.find((f) => f.slug === 'motorhome') || this.fleetTypes[0];
        }
        return this.fleetTypes[0];
    }

    get defaultFleetCategory() {
        return this.getDefault();
    }

    getFleetTypeFromProps(props: unknown) {
        return this.fleetTypes.find((f) => f.slug === get(props, 'match.params.fleetType'));
    }

    isValidFleetTypeSlug(slug?: string): slug is string {
        return Boolean(slug && this.getFleetTypeBySlug(slug));
    }
}

export default new FleetTypesStore();
