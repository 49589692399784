import { datadogRum } from '@datadog/browser-rum';
import { jucyInsights } from '#/lib/jucyInsights';
import config from '#/config';

if (config.insightsEnabled) {
    datadogRum.init({
        service: 'oneida',
        applicationId: '460a8434-a6fd-4d30-b70f-b6cb7603d07e',
        clientToken: 'pube7878312dc0a7e9b1ab676b6eeda70b6',

        site: 'us5.datadoghq.com',
        env: process.env.NEXT_PUBLIC_ENV || 'dev',
        version: process.env.NEXT_PUBLIC_APP_VERSION || undefined,
        trackSessionAcrossSubdomains: true,
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        storeContextsAcrossPages: true,
        usePartitionedCrossSiteSessionCookie: true,
        trackResources: true,
        trackLongTasks: true,
        allowFallbackToLocalStorage: true,
        enableExperimentalFeatures: ['feature_flags'],
        defaultPrivacyLevel: 'allow',
        allowedTracingUrls: [(url) => jucyInsights.shouldTraceUrl(url)],
        proxy: (options) => `https://${jucyInsights.resolveInsightsDomain(window.location.href)}/ingest${options.path}?${options.parameters}`,
    });
}
