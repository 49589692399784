import { ProductLine } from '@jucy/rentals-api-client/rentals-api-v3';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';

const compareLine = (a: ProductLine, b: ProductLine) => {
    if (a.productId !== b.productId) {
        return false;
    }
    if (a.total.value !== b.total.value) {
        return false;
    }
    if (a.qty !== b.qty) {
        return false;
    }
    return a.name === b.name;
};

export interface BookingSummaryPriceRowProps {
    item: ProductLine;
    amendedItem?: ProductLine;
    className?: string;
    sx?: SxProps<Theme>;
}

const BookingSummaryAddOnRow: React.FC<BookingSummaryPriceRowProps> = observer(({ item, amendedItem, ...props }) => {
    const selected = amendedItem || item;
    const hasChanged = Boolean(item && amendedItem && !compareLine(item, amendedItem));
    return (
        <>
            {hasChanged ? (
                <BookingSummaryAddOnRowComponent
                    item={{
                        ...item,
                        isExcluded: true,
                    }}
                    {...props}
                />
            ) : null}
            <BookingSummaryAddOnRowComponent data-cy="insurance-summary" item={selected} {...props} />
        </>
    );
});
BookingSummaryAddOnRow.displayName = 'BookingSummaryAddOnRow';

const BookingSummaryAddOnRowComponent: React.FC<BookingSummaryPriceRowProps> = observer(({ item, sx, ...props }) => {
    if (!item) {
        return null;
    }
    let title = '';
    let subtitle: React.ReactNode = '';

    if (item.allowMultiple) {
        title = item.qty + ' x ';
    }
    title += item.name;
    if (item.productType !== 'Percentage' && item.total.value >= 0 && item.price?.value >= 0) {
        const unit = item.productType === 'Daily' ? `day x ${item.rentalDays} day${item.rentalDays !== 1 ? 's' : ''}` : 'each';
        subtitle = (
            <>
                <CurrencyFormatter price={item.price} />/{unit}
            </>
        );
    }
    if (item.price?.value < 0 && (title.toLowerCase().includes('adjustment') || title.toLowerCase().includes('discount') || title.toLowerCase().includes('offset'))) {
        title = item.name;
        subtitle = '';
    }
    return <BookingSummaryPriceRow sx={{ textDecoration: item.isExcluded ? 'line-through' : undefined }} title={title} subtitle={subtitle} price={item.total} {...props} />;
});
BookingSummaryAddOnRowComponent.displayName = 'BookingSummaryAddOnRowComponent';
export default BookingSummaryAddOnRow;
