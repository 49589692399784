import React from 'react';
import { Typography, Grid2 as Grid } from '@mui/material';
import clsx from 'clsx';
import CurrencyStore from '../../../store/CurrencyStore';
import CurrencyFormatter, { Price } from '../../CurrencyFormatter/CurrencyFormatter';
import { Grid2Props } from '@mui/material/Grid2';

export interface AgentSummaryPriceRowProps extends Grid2Props {
    title?: string;
    subtitle?: React.ReactNode;
    amendment?: Price;
    price?: Price;
    className?: string;
}

const AgentSummaryPriceRowInner: React.FC<AgentSummaryPriceRowProps> = ({ title, subtitle, price, className, ...props }) => (
    <>
        <Grid lineHeight={1} size={6} {...props}>
            <Typography variant="caption" lineHeight={0.8}>
                {title}
                {subtitle && (
                    <>
                        <br />
                        {subtitle}
                    </>
                )}
            </Typography>
        </Grid>
        <Grid display="flex" justifyContent="right" size={6} {...props}>
            <Typography variant="caption" fontSize="16px" whiteSpace="nowrap" lineHeight={1}>
                {price ? (
                    <>
                        {CurrencyStore.selectedCurrencyCode || price?.currencyCode || ''} {price ? <CurrencyFormatter price={price} /> : null}
                    </>
                ) : null}
            </Typography>
        </Grid>
    </>
);
AgentSummaryPriceRowInner.displayName = 'AgentSummaryPriceRowInner';

const AgentSummaryPriceRow: React.FC<AgentSummaryPriceRowProps> = ({ title, subtitle, price, amendment, className, ...props }) => {
    const showAmendment = amendment && amendment?.value !== price?.value;
    return (
        <Grid container spacing={0} marginBottom={1.5} className={clsx('trip-info animate__animated animate__fadeIn', className)} {...props}>
            {showAmendment && amendment ? (
                <>
                    <AgentSummaryPriceRowInner title={title} subtitle={subtitle} price={price} sx={{ textDecoration: 'line-through' }} />
                    <AgentSummaryPriceRowInner title={title} subtitle={subtitle} price={amendment} />
                </>
            ) : (
                <AgentSummaryPriceRowInner title={title} subtitle={subtitle} price={price} />
            )}
        </Grid>
    );
};
AgentSummaryPriceRow.displayName = 'BookingSummaryPriceRow';
export default AgentSummaryPriceRow;
