import { getPaymentUniqueKey } from '#/lib/getPaymentUniqueKey';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TranslationStore from '../../../store/TranslationStore';
import { ObeProductLine } from '../../../types/ObeProductLine';
import { formatCurrency } from '../../CurrencyFormatter/CurrencyFormatter';
import { useBookingSummaryContext } from '../BookingSummaryContext';
import BookingSummaryAddOnRow from './BookingSummaryAddOnRow';
import BookingSummaryGroupItem from './BookingSummaryGroupItem';
import BookingSummaryPriceRow from './BookingSummaryPriceRow';

const groupAndSortProductLines = (items: ObeProductLine[]): ObeProductLine[] => {
    const groupedItems = items.reduce(
        (acc, item) => {
            const key = item.productId;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        },
        {} as Record<string, ObeProductLine[]>
    );
    const sortedItems = Object.values(groupedItems).flatMap((group) => group.sort((a, b) => a.sortOrder - b.sortOrder));

    return sortedItems;
};

const ExtrasSummary: React.FC = observer(() => {
    const context = useBookingSummaryContext();
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const bundleItemIds = new Set([...(context.summary.bundledItems || []), ...(context.summary.amendments?.bundledItems || [])].map((item) => item.productId));
    const filterBundledItems = (item: ObeProductLine) => item.category !== 'BundledProduct' && !bundleItemIds.has(item.productId);

    const mandatoryItems = groupAndSortProductLines(
        ((context.summary?.action == 'edit' && context.summary.amendments?.mandatoryItems) || context.summary.mandatoryItems || []).filter(filterBundledItems)
    );
    const selectedExtras = groupAndSortProductLines(
        ((context.summary?.action == 'edit' && context.summary.amendments?.selectedExtras) || context.summary.selectedExtras || []).filter(filterBundledItems)
    );
    const bundledItems = groupAndSortProductLines((context.summary?.action == 'edit' && context.summary.amendments?.bundledItems) || context.summary.bundledItems || []);
    const payments = (context.summary?.action == 'edit' && context.summary.amendments?.payments) || context.summary.payments || [];
    const paymentType = (context.summary?.action == 'edit' && context.summary.amendments?.paymentType) || context.summary.paymentType;

    return bundledItems.length || mandatoryItems.length || selectedExtras.length || paymentType?.merchantFeeRate ? (
        <BookingSummaryGroupItem
            title={t('trip_summary.group.extras_fees') || ''}
            className="animate__animated animate__fadeIn"
            isEditable={Boolean(context.summary.selectedExcess)}
            editRouteParams={{
                activeTab: 'extras',
            }}
        >
            {mandatoryItems.map((item) => (
                <BookingSummaryAddOnRow data-cy={`addon-summary-${item.productCode || item.productId}`} item={item} key={item.productId} className="mb-2" />
            ))}
            {selectedExtras.map((item) => (
                <BookingSummaryAddOnRow data-cy={`addon-summary-${item.productCode || item.productId}`} item={item} key={item.productId} className="mb-2" />
            ))}
            {bundledItems.map((item) => (
                <BookingSummaryAddOnRow data-cy={`addon-summary-${item.productCode || item.productId}`} item={item} key={item.productId} className="mb-2" />
            ))}
            {payments
                ?.filter((p) => p.merchantFeeAmount?.value)
                ?.map((payment) => <BookingSummaryPriceRow title={payment.merchantFeeName} price={payment.merchantFeeAmount} key={getPaymentUniqueKey(payment)} />)}
            {paymentType?.merchantFee ? (
                <BookingSummaryPriceRow
                    title={'Payment merchant fee'}
                    subtitle={paymentType.merchantFeeRate ? `${paymentType.merchantFeeRate * 100}% of ${formatCurrency(paymentType.subTotal)}` : ''}
                    price={paymentType.merchantFee}
                />
            ) : null}
        </BookingSummaryGroupItem>
    ) : null;
});
ExtrasSummary.displayName = 'ExtrasSummary';
export default ExtrasSummary;
