import TranslationStore from '#/store/TranslationStore';
import { CircularProgress, Stack, Typography, Grid2 as Grid } from '@mui/material';
import { Field, Form as FormikForm, Formik, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { FormikHelpers, FormikProps } from 'formik/dist/types';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import AppStateStore from '../../../store/AppStateStore';
import appState from '../../../store/AppStateStore';
import { BookingCart } from '../../../types/BookingCart';
import { JucyErrorAlert } from '../../Alert/JucyErrorAlert';
import { JucyButton } from '../../Button/Button';
import { PageTitle } from '../../Typograpgy/Title';
import { CommonHirerDetailsFormFields } from './components/CommonHirerDetailsFormFields';

const agentHirerDetailsValuesSchema = Yup.object().shape({
    firstName: Yup.string().trim().required('Required'),
    lastName: Yup.string().trim().required('Required'),
    mobileNumber: Yup.string().trim().required('Required'),
    email: Yup.string().email().trim().required('Required'),
    agentVoucherReference: Yup.string().trim(),
    agentName: Yup.string().trim(),
    numberOfPeople: Yup.number().min(1).integer().optional(),
});

export type AgentHirerDetailsFormValues = Yup.InferType<typeof agentHirerDetailsValuesSchema>;

export const initialAgentHirerDetailsFormValues: AgentHirerDetailsFormValues = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    agentVoucherReference: '',
    agentName: '',
};

interface AgentHirerDetailsFormFieldsProps {
    isRequest?: boolean;
    summary: BookingCart;
    form: FormikProps<AgentHirerDetailsFormValues>;
    readonly?: boolean;
    children?: React.ReactNode;
}

const AgentHirerDetailsFormFields: React.FC<AgentHirerDetailsFormFieldsProps> = ({ form, summary, ...props }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const { dirty, isValid, submitForm, isSubmitting } = useFormikContext();
    useEffect(() => {
        appState.orderSummaryFooter.enableNextBtn({
            onClick: () => submitForm(),
            disabled: !(dirty && isValid) || isSubmitting,
            endIcon: isSubmitting ? <CircularProgress sx={{ color: 'inherit' }} size={18} /> : undefined,
        });
    }, [dirty, submitForm, isValid, isSubmitting]);
    if (!summary?.hirerDetails) {
        return (
            <JucyErrorAlert title="Whoops an unexpected error occurred">
                No active booking found
                <br />
                Return to the <Link to={`/${AppStateStore.mode}`}>Search Page</Link>
            </JucyErrorAlert>
        );
    }
    return (
        <FormikForm className="rentals-hirer-details-form" {...props}>
            <PageTitle sx={{ py: { xs: 1, md: 3 } }} data-cy="page-title">
                Hirer Details
            </PageTitle>
            <p>We need some info to save your quote. It will help you to find it and manage quotes and bookings at a later stage.</p>
            <p>This information will only be used to help you find the quote.</p>
            <Grid container spacing={2}>
                <CommonHirerDetailsFormFields summary={summary} mode="agent" />
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <Field
                        component={TextField}
                        fullWidth
                        size="small"
                        label={t('hirer_details.group.number_of_people')}
                        type="number"
                        name="numberOfPeople"
                        inputProps={{
                            'data-cy': 'number-of-people',
                            step: 1,
                        }}
                        disabled={isSubmitting}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" sx={{ mt: 2, mb: -1 }}>
                Partner details
            </Typography>
            <Grid container spacing={2}>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <Field
                        component={TextField}
                        fullWidth
                        size="small"
                        label="Voucher reference (optional)"
                        type="text"
                        name="agentVoucherReference"
                        data-cy="agent-voucher-ref"
                        disabled={form.isSubmitting}
                        helperText="This field will be auto generated if not filled."
                    />
                </Grid>
                <Grid
                    size={{
                        xs: 12,
                        md: 6,
                    }}
                >
                    <Field
                        component={TextField}
                        fullWidth
                        size="small"
                        label="Agent Name (optional)"
                        type="text"
                        name="agentName"
                        disabled={form.isSubmitting}
                        helperText="The name of the person creating the booking."
                    />
                </Grid>
            </Grid>
            <Stack mb={2} py={1} direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={1}>
                {form.status?.error ? <JucyErrorAlert title="Create quote" message={form.status.error} className="animate__animated animate__fadeIn" sx={{ mr: 2, width: '100%' }} /> : null}
                <JucyButton
                    sx={{ flex: '0 0 170px' }}
                    type="submit"
                    variant="contained"
                    disabled={form.isSubmitting || !form.isValid}
                    showLoader={form.isSubmitting}
                    data-cy="save-quote-btn"
                >
                    {!summary.isQuote && summary.action === 'edit' ? 'Save amendments' : 'Save and review'}
                </JucyButton>
            </Stack>
        </FormikForm>
    );
};

export interface AgentHirerDetailsFormProps {
    summary?: BookingCart;
    initialValues: AgentHirerDetailsFormValues;
    onSubmit: (values: AgentHirerDetailsFormValues, formikHelpers: FormikHelpers<AgentHirerDetailsFormValues>) => void | Promise<void>;
    children?: React.ReactNode;
}

const AgentHirerDetailsForm: React.FC<AgentHirerDetailsFormProps> = observer(({ summary, initialValues, onSubmit, children }) => (
    <Formik initialValues={initialValues} validationSchema={agentHirerDetailsValuesSchema} onSubmit={onSubmit}>
        {(form) => (
            <AgentHirerDetailsFormFields summary={summary as BookingCart} form={form}>
                {children}
            </AgentHirerDetailsFormFields>
        )}
    </Formik>
));

AgentHirerDetailsForm.displayName = 'AgentHirerDetailsForm';

export default AgentHirerDetailsForm;
