import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, AutocompleteProps as MuiAutocompleteProps, Stack, TextField } from '@mui/material';
import useId from '@mui/utils/useId';
import { useField } from 'formik';
import countries from '../../../lib/Countries';
import { CountryInfo } from '../../../lib/CountryInfo';

const countryCodeToFlag = (isoCode: string) =>
    typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;

const CountrySelectOption: React.FC<{ country: CountryInfo }> = ({ country }) => {
    const flag = useMemo(() => countryCodeToFlag(country.code || ''), [country]);
    return (
        <Stack spacing={1} direction="row" alignItems="end">
            {flag ? <div>{flag}</div> : null}
            <div>{country.label}</div>
            <small>({country.code})</small>
            <small>+{country.phone}</small>
        </Stack>
    );
};

type AutocompleteProps = MuiAutocompleteProps<CountryInfo, false, false, false>;

export interface CountrySelectProps extends Omit<AutocompleteProps, 'onChange' | 'value' | 'options' | 'renderInput' | 'label'> {
    name: string;
    label?: string | null;
    onChange?: (value: CountryInfo | null) => void;
}

const renderOption: AutocompleteProps['renderOption'] = (props, option) => (
    <li {...props} data-country-code={option.code} key={(props as { key: string }).key || option.code}>
        <CountrySelectOption country={option} />
    </li>
);

const CountrySelect: React.FC<CountrySelectProps> = ({ className, onChange, onOpen, onClose, id: propsId, ...props }) => {
    const [field, meta, helpers] = useField(props.name);
    const id = useId(propsId);
    const value = useMemo(() => countries.find((o) => o.label === field.value), [field.value]);
    const inputRef = useRef<HTMLInputElement>();
    const [isAutoFilled, setIsAutoFilled] = useState(false);
    const invalid = Boolean(meta.error && meta.touched);

    useEffect(() => {
        const input = inputRef.current;
        const handleAutoComplete = (e: AnimationEvent) => {
            if (e.animationName === 'mui-auto-fill') {
                setIsAutoFilled(true);
            }
            if (e.animationName === 'mui-auto-fill-cancel') {
                setIsAutoFilled(false);
            }
        };
        if (input && input.dataset.hasCountrySelectAutocomplete !== 'true') {
            input.addEventListener('animationstart', handleAutoComplete);
            input.dataset.hasCountrySelectAutocomplete = 'true';
        }
        return () => {
            if (input) {
                input.removeEventListener('animationstart', handleAutoComplete);
                input.dataset.hasCountrySelectAutocomplete = 'false';
            }
        };
    }, []);

    const handleChange = useCallback<Exclude<AutocompleteProps['onChange'], undefined>>(
        (_e, value, _reason) => {
            helpers.setValue(value?.label);
            if (onChange) {
                onChange(value);
            }
        },
        [helpers, onChange]
    );

    const handleOpen = useCallback<Exclude<AutocompleteProps['onOpen'], undefined>>(
        (e) => {
            onOpen?.(e);
        },
        [onOpen]
    );

    const handleClose = useCallback<Exclude<AutocompleteProps['onClose'], undefined>>(
        (e, reason) => {
            onClose?.(e, reason);
        },
        [onClose]
    );

    return (
        <Autocomplete<CountryInfo>
            id={id}
            size="small"
            options={countries || []}
            value={value || null}
            autoHighlight
            getOptionLabel={(option) => option?.label || ''}
            onBlur={field.onBlur}
            renderOption={renderOption}
            onOpen={handleOpen}
            onClose={handleClose}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={invalid}
                    helperText={invalid ? meta.error : ''}
                    inputRef={inputRef}
                    label={props.label}
                    variant="outlined"
                    slotProps={{
                        htmlInput: {
                            ...params.inputProps,
                            onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                const inputValue = (e.target as HTMLInputElement)?.value;
                                if (isAutoFilled && inputValue) {
                                    const country = countries.find((o) => o.label === inputValue);
                                    helpers.setValue(country?.label);
                                    if (country) {
                                        waitForAndClick(`.MuiAutocomplete-option[data-country-code="${country.code}"]`);
                                    }
                                }
                                params?.inputProps?.onChange?.(e);
                            },
                        },
                    }}
                />
            )}
            onChange={handleChange}
        />
    );
};

const waitForAndClick = (menuItemSelector?: string) => {
    if (!menuItemSelector) {
        return;
    }

    const findAndClick = () => {
        const menuItem = document.querySelector<HTMLLIElement>(menuItemSelector);
        if (menuItem) {
            menuItem.click();
            return true;
        }
        return false;
    };
    if (findAndClick()) {
        return;
    }
    const maxAttempts = 100;
    let attempts = 0;
    const timer = window.setInterval(() => {
        if (attempts > maxAttempts) {
            clearInterval(timer);
            return;
        }
        try {
            if (findAndClick()) {
                clearInterval(timer);
            }
        } catch (e) {
            clearInterval(timer);
        }
        attempts++;
    }, 5);

    return timer;
};

export default CountrySelect;
