import { VelocityLogo } from '#/components/Velocity/VelocityLogo';
import FleetCategoryAvailability from '#/lib/api/model/FleetCategoryAvailability';
import { formatRewardPoints } from '#/lib/formatRewardPoints';
import { isFleetCategoryEarnEligible } from '#/lib/velocity/isFleetCategoryEarnEligible';
import { useIsVelocityEnabled } from '#/lib/velocity/useIsVelocityEnabled';
import { useVelocityPointsEarnEstimate } from '#/lib/velocity/useVelocityPointsEarnEstimate';
import TranslationStore from '#/store/TranslationStore';
import { Typography, Grid2 as Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTeaserContext } from '#/components/FleetCategory/components/Teaser/TeaserContext';

export const VelocityFleetCategoryTeaserPanel = observer(({ fleetCategory }: { fleetCategory: FleetCategoryAvailability }) => {
    const { deal } = useTeaserContext();
    const isVelocityEnabled = useIsVelocityEnabled();
    const fleetCategoryEligible = isFleetCategoryEarnEligible(fleetCategory.asV3FleetCategory());
    const { basePoints, bonusMultiplierPoints, bonusFixedPoints, bonusPoints, totalPoints, hasBonus } = useVelocityPointsEarnEstimate(fleetCategory.total);
    const { t } = useTranslation(TranslationStore.currentNamespace);
    if (!isVelocityEnabled || !fleetCategoryEligible || !totalPoints) {
        return null;
    }
    const bonusValid = deal?.enabled && hasBonus;

    return (
        <Grid container sx={{ pb: 2 }} size={12}>
            <Grid
                sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                size={{
                    xs: 12,
                    sm: 4,
                }}
            >
                <VelocityLogo />
            </Grid>
            <Grid
                sx={{ display: 'flex', alignItems: 'center' }}
                size={{
                    xs: 12,
                    sm: 8,
                }}
            >
                <Typography sx={{ pl: { xs: 0, sm: 1 }, pr: { xs: 0, sm: 1 }, pt: { xs: 0, sm: 0.5 } }}>
                    {t(`fleet_result_list.vff-earn-estimate${bonusValid ? '-bonus' : ''}`, {
                        basePoints: formatRewardPoints(basePoints),
                        bonusMultiplier: bonusValid ? formatRewardPoints(bonusMultiplierPoints) : 0,
                        bonusFixedPoints: bonusValid ? formatRewardPoints(bonusFixedPoints) : 0,
                        bonusPoints: bonusValid ? formatRewardPoints(bonusPoints) : 0,
                        totalPoints: bonusValid ? formatRewardPoints(totalPoints) : formatRewardPoints(basePoints),
                    })}
                    <Typography component="sup" sx={{ fontSize: '0.7rem' }}>
                        †
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
});
