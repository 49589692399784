import InfoText from '#/components/Text/InfoText';
import { VelocityMembershipIdInputField } from '#/components/Velocity/VelocityMembershipIdInputField';
import { useBrand } from '#/store/BrandStore';
import { JucyCountryCode } from '@jucy/rentals-common';
import { Box, CircularProgress, FormHelperText, Stack, Typography, Grid2 as Grid } from '@mui/material';
import { Field, Form as FormikForm, Formik, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import { FormikHelpers, FormikProps } from 'formik/dist/types';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTradedoublerSetConfig } from '../../../lib/SalesMonitoring/Providers/tradedoubler';
import appState from '../../../store/AppStateStore';
import ReservationStore from '../../../store/ReservationStore';
import TranslationStore from '../../../store/TranslationStore';
import { BookingCart } from '../../../types/BookingCart';
import { JucyErrorAlert } from '../../Alert/JucyErrorAlert';
import { JucyButton } from '../../Button/Button';
import CurrencyFormatter from '../../CurrencyFormatter/CurrencyFormatter';
import EmailQuoteButton from '../../EmailQuote/EmailQuoteButton';
import { LicenceCountrySelect } from '../../form/fields';
import Checkbox from '../../form/fields/Checkbox';
import PaymentOptions from '../../PaymentOptions/PaymentOptions';
import ReservationSummaryCard from '../../Reservation/ReservationSummaryCard';
import { PageTitle } from '../../Typograpgy/Title';
import { CommonHirerDetailsFormFields } from './components/CommonHirerDetailsFormFields';
import { DirectHirerDetailsFormValues, hirerDetailsValuesSchema } from './DirectHirerDetailsSchema';
import { VelocityHirerDetailsFormFooter } from '#/components/Velocity/VelocityHirerDetailsFormFooter';

export const initialDirectHirerDetailsFormValues: Partial<DirectHirerDetailsFormValues> = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    driversLicenceCountry: '',
    paymentType: '',
    termsAndConditions: false,
    mailingList: false,
};

interface DirectHirerDetailsFormFieldsProps {
    summary: BookingCart;
    form: FormikProps<DirectHirerDetailsFormValues>;
    readonly?: boolean;
    displayEmailQuote?: boolean;
}

const DirectHirerDetailsFormFields: React.FC<DirectHirerDetailsFormFieldsProps> = observer(({ form, summary, readonly, displayEmailQuote, ...props }) => {
    const { touched, errors } = form;
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const paymentTypeInvalid = Boolean(errors.paymentType && touched.paymentType);
    const {
        dirty,
        isValid,
        submitForm,
        isSubmitting,
        values: { paymentType },
    } = useFormikContext<DirectHirerDetailsFormValues>();
    const brandStore = useBrand();

    useEffect(() => {
        appState.orderSummaryFooter.enableNextBtn({
            onClick: () => submitForm(),
            disabled: !(dirty && isValid) || isSubmitting,
            endIcon: isSubmitting ? <CircularProgress sx={{ color: 'inherit' }} size={18} /> : undefined,
        });
    }, [dirty, submitForm, isValid, isSubmitting]);

    useTradedoublerSetConfig(28076, {
        products: [
            {
                id: summary?.fleetCategory?.categoryCode,
                price: summary?.totalPrice.value,
                currency: summary?.totalPrice.currencyCode,
                name: summary?.fleetCategory?.name,
                qty: '1',
            },
        ],
    });
    if (!summary?.fleetCategory) {
        return null;
    }

    return (
        <>
            <PageTitle sx={{ py: { xs: 1, md: 3 } }} data-cy="page-title">
                {t('hirer_details.group.title')}
            </PageTitle>
            <ReservationSummaryCard className="pb-4" summary={summary} />
            <FormikForm className="rentals-hirer-details-form " {...props}>
                {!summary.isOnRequest ? (
                    <>
                        <h3 className="fw-bold h6 mt-4 mb-4">Payment Options</h3>
                        <PaymentOptions summary={summary} />

                        {paymentTypeInvalid ? <FormHelperText error={true}>{errors.paymentType}</FormHelperText> : null}
                    </>
                ) : null}
                <h3 className="fw-bold h6 mt-4 mb-4">{t('hirer_details.group.driver_details')}</h3>
                <Grid container spacing={2}>
                    <CommonHirerDetailsFormFields summary={summary} />
                    <Grid
                        size={{
                            xs: 12,
                            md: 6,
                        }}
                    >
                        <LicenceCountrySelect
                            name="driversLicenceCountry"
                            label={t('hirer_details.group.drivers_license_description')}
                            disabled={form.isSubmitting}
                            data-cy="licence-country"
                        />
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            md: 6,
                        }}
                    >
                        <Field
                            component={TextField}
                            fullWidth
                            size="small"
                            label={t('hirer_details.group.number_of_people')}
                            type="number"
                            name="numberOfPeople"
                            inputProps={{
                                'data-cy': 'number-of-people',
                                min: 1,
                                step: 1,
                            }}
                            disabled={isSubmitting}
                        />
                    </Grid>

                    <VelocityMembershipIdInputField
                        fleetCategory={summary.fleetCategory}
                        disabled={isSubmitting}
                        paymentType={paymentType}
                        purchaseValue={summary.totalPriceWithMerchantFee}
                        countryCode={summary.pickUpLocation?.CountryCode as JucyCountryCode}
                    />

                    <Grid size={12}>
                        <Box>
                            <Checkbox
                                type="checkbox"
                                data-cy="accept-terms"
                                disabled={form.isSubmitting || readonly}
                                id="termsAndConditions"
                                name="termsAndConditions"
                                label={
                                    <>
                                        {t('hirer_details.group.terms_and_conditions_1')}{' '}
                                        <a
                                            title={t('hirer_details.group.terms_and_conditions_2') || ''}
                                            href={brandStore.termsPageUrl({ countryCode: summary.pickUpLocation?.CountryCode })}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('hirer_details.group.terms_and_conditions_2')}{' '}
                                        </a>
                                        {t('hirer_details.group.terms_and_conditions_3')}{' '}
                                        <a
                                            title={t('hirer_details.group.terms_and_conditions_4') || ''}
                                            href={brandStore.privacyPageUrl({ countryCode: summary.pickUpLocation?.CountryCode })}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t('hirer_details.group.terms_and_conditions_4')}{' '}
                                        </a>
                                        {t('hirer_details.group.terms_and_conditions_5')}
                                    </>
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid size={12}>
                        <Box>
                            <Checkbox id="mailingList" type="checkbox" name="mailingList" disabled={form.isSubmitting} data-cy="opt-in" label={t('hirer_details.group.marketing_emails')} />
                        </Box>
                    </Grid>
                </Grid>

                {summary.isOnRequest && <OnRequestInfo />}
                <>
                    <OnRequestAmount summary={summary} />
                    {form.status?.error ? (
                        <JucyErrorAlert title={t('email_quote.email_error')} message={form.status.error} className="animate__animated animate__fadeIn" sx={{ mr: 2, width: '100%' }} />
                    ) : null}

                    <Stack mb={2} py={1} direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                        {displayEmailQuote ? <EmailQuoteButton data-cy="driver-email-quote-btn" disabled={form.isSubmitting} /> : null}
                        <JucyButton type="submit" variant="contained" disabled={form.isSubmitting} showLoader={form.isSubmitting} data-cy="save-quote-btn">
                            {t('hirer_details.group.continue')}
                        </JucyButton>
                    </Stack>
                </>
                <VelocityHirerDetailsFormFooter fleetCategory={summary.fleetCategory} />
            </FormikForm>
        </>
    );
});
const OnRequestAmount: React.FC<{ summary: BookingCart }> = ({ summary }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    return (
        <>
            {summary.isOnRequest && (
                <div className="fw-bold w-100 text-end">
                    {t('hirer_details.group.to_pay_now')} {ReservationStore.onRequestHoldAmount.currencyCode}
                    <CurrencyFormatter price={ReservationStore.onRequestHoldAmount} />
                </div>
            )}
        </>
    );
};

const OnRequestInfo = observer(() => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    return (
        <InfoText sx={{ my: 2 }}>
            <Typography variant="subtitle2" gutterBottom={true} lineHeight={1.2} mb={2}>
                {t('hirer_details.group.about_your_request')}
            </Typography>
            <Typography variant="body1">{t('hirer_details.group.popular_vehicle')}</Typography>
            <Typography variant="body1">{t('hirer_details.group.balance_due_message')}</Typography>
        </InfoText>
    );
});
OnRequestInfo.displayName = 'OnRequestInfo';

export interface DirectHirerDetailsFormProps {
    summary?: BookingCart;
    initialValues: Partial<DirectHirerDetailsFormValues>;
    onSubmit: (values: DirectHirerDetailsFormValues, formikHelpers: FormikHelpers<DirectHirerDetailsFormValues>) => void | Promise<void>;
    children?: React.ReactNode;
    displayEmailQuote?: boolean;
}

const DirectHirerDetailsForm: React.FC<DirectHirerDetailsFormProps> = observer(({ initialValues, onSubmit, children, summary, displayEmailQuote }) =>
    summary ? (
        <Formik initialValues={initialValues as DirectHirerDetailsFormValues} validateOnMount={false} validationSchema={hirerDetailsValuesSchema} onSubmit={onSubmit}>
            {(form) => (
                <>
                    <DirectHirerDetailsFormFields form={form} summary={summary} displayEmailQuote={displayEmailQuote} />
                    {children}
                </>
            )}
        </Formik>
    ) : null
);
DirectHirerDetailsForm.displayName = 'DirectHirerDetails';

export default DirectHirerDetailsForm;
